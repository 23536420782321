import { Map } from "./components/Map";

import Alpine from 'alpinejs';
import intersect from '@alpinejs/intersect'

import Swup from 'swup';
import SwupA11yPlugin from '@swup/a11y-plugin';
import SwupFragmentPlugin from '@swup/fragment-plugin';
import SwupHeadPlugin from '@swup/head-plugin';
import SwupMatomoPlugin from '@swup/matomo-plugin';
import SwupProgressPlugin from '@swup/progress-plugin';

window.map = null;

const swup = new Swup({ 
    ignoreVisit: (url, { el } = {}) => (el?.matches('[data-no-swup], [href$=".pdf"]')),
    containers: ['#main', '#menu'],
    plugins: [
        new SwupFragmentPlugin({ 
            rules: [
                { 
                    from: ['/spaces'],
                    to: ['/spaces/:space'],
                    containers: ['#space-detail', '#spaces-list'],
                    focus: ['#space-detail'],
                    name: 'space-detail',
                },
                {
                    from: "/spaces/:space",
                    to: "/spaces/:space",
                    containers: ['#space-detail', '#spaces-list', '#menu'],
                    focus: ['#space-detail'],
                    name: 'space-detail',
                },
                {
                    from: "/spaces/:space",
                    to: "/spaces",
                    containers: ['#space-detail', '#spaces-list', '#menu'],
                    focus: ['#main'],
                    name: 'space-detail',
                },
                {
                    from: ['/de/spaces'],
                    to: ['/de/spaces/:space'],
                    containers: ['#space-detail', '#spaces-list'],
                    focus: ['#space-detail'],
                    name: 'space-detail',
                },
                {
                    from: "/de/spaces/:space",
                    to: "/de/spaces/:space",
                    containers: ['#space-detail', '#spaces-list', '#menu'],
                    focus: ['#space-detail'],
                    name: 'space-detail',
                },
                {
                    from: "/de/spaces/:space",
                    to: "/de/spaces",
                    containers: ['#space-detail', '#spaces-list', '#menu'],
                    focus: ['#main'],
                    name: 'space-detail',
                },
            ] 
        }),
        new SwupA11yPlugin({
            announcements: {
              'en': {
                visit: 'Navigated to: {title}',
                url: 'New page at {url}'
              },
              'de': {
                visit: 'Navigiert zu: {title}',
                url: 'Neue Seite unter {url}'
              },
            }
          }
        ),
        new SwupHeadPlugin(),
        new SwupMatomoPlugin(),
        new SwupProgressPlugin(
            {
                className: 'swup-progress-bar',
                transition: 300,
                delay: 300,
                initialValue: 0.25,
                finishAnimation: true
            }              
        ),
    ] 
});

window.swup = swup;

swup.hooks.on('page:view', (visit) => {
    pageVisit();
});

pageVisit();

function pageVisit() {
    if (window.location.href.includes('/spaces')) {
        let slug = window.location.href.split('/').pop();
        if (slug == 'spaces') {
            if (map != null) return;
            initMap();
        } else {
            if (map != null) return window.map.center(window.map.getMarker(slug).getLatLng());
            initMap(slug);
        }
    } else {
        window.map = null;
    }
}

function initMap(space = null) {
    let mapEl = document.querySelector('#map');
    if (!mapEl.classList.contains('is-init')) {
        window.map = new Map(document.querySelector('#map'), '/participants.json', space);
    }
}

Alpine.start();
Alpine.plugin(intersect);