import 'leaflet';
import "leaflet-active-area";
import { LatLng } from 'leaflet';

const viennaCoords = [48.2082, 16.3738];

const mapOptions = {
    zoomControl: false,
    minZoom: 11,
    tap: false, // fix a safari bug
    // maxBounds: L.latLngBounds(corner1, corner2),
}

export class Map {
    constructor(selector, locationDataUrl, initialCenter = null) {
        this.$map = L.map(selector, mapOptions)
        .setActiveArea('activeArea', true, false)

        L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/256/{z}/{x}/{y}@2x?access_token={accessToken}', {
            attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
            maxZoom: 18,
            id: 'independentspaceindex/cluwlgrga002501qvd3bb2fl2',
            accessToken: 'pk.eyJ1IjoiaW5kZXBlbmRlbnRzcGFjZWluZGV4IiwiYSI6ImNrNW1tbjA5bDAza24zZXBkanFlc2ppcTQifQ.JnL1exWS78WBLlZrXn6BuQ'
        }).addTo(this.$map);

        this.markers = [];

        this.getData(locationDataUrl).then(locationData => this.init(locationData, initialCenter));
    }

    async getData(url) {
        let response = await fetch(url);
        let data = await response.json();
        return data;
    }
    
    addMarkers(locationData) {
        this.markerLayerGroup = L.featureGroup();
        locationData.forEach(dat => {
            if (dat.lat == 'none' || dat.lng == 'none') return;
            let marker = L.marker(new LatLng(dat.lat, dat.lng), {
                slug: dat.slug,
                icon: L.divIcon({
                    className: `marker`,
                    html: `<a href="${dat.url}"><div class="marker-icon ${dat.status}"></div><div class="name">${dat.name}</div></a>`,
                    iconSize: new L.Point(20, 20),
                }),
            });
            this.markers.push(marker);
            this.markerLayerGroup.addLayer(marker);
        });
        this.markerLayerGroup.addTo(this.$map);
        // this.$map.setMaxBounds(this.markerLayerGroup.getBounds())
    }

    getMarker(slug) {
        return this.markers.find(m => m.options.slug == slug);
    }

    center(latlng = new LatLng(viennaCoords), zoom = 17) {
        let center = latlng;
        this.$map.flyTo(center, zoom);
    }

    filterBy(value) {
        this.markers.forEach(m => {
            if (value == 'all' || m.options.types.split(',').includes(value)) {
                return m._icon.classList.remove('is-disabled');
            }
            m._icon.classList.add('is-disabled');
        })
    }

    init(locationData, initialCenter) {
        this.$map.getContainer().classList.add('is-init');
        this.addMarkers(locationData);
        if (initialCenter != null) {
            this.$map.setView(this.getMarker(initialCenter).getLatLng(), 17);
        } else {
            this.$map.setView(viennaCoords, 13);
        }
    }
}